import React from "react";
import cover from "../image/cover.png";

interface TabHomeProps {
  selectTabRead: any;
}

const TabHome = (props: TabHomeProps) => {
  //Displays the title and the 'read' link

  return (
    <div className="home">
      <div className="homeCover">
        <img src={cover} alt="Signals in the night cover" />
      </div>

      <div className="menu">
        <div className="title">
          <h1>signals in the night</h1>
          <h3>octav codrea</h3>
        </div>

        <div className="links">
          <h3 onClick={() => props.selectTabRead()}>read</h3>
        </div>
      </div>
    </div>
  );
};

export default TabHome;
