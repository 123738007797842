import React from "react";
import cover from "./image/cover.png";
import coverback from "./image/cover-back.png";
import d1a from "./image/d1a.png";
import d1b from "./image/d1b.png";
import d2a from "./image/d2a.png";
import d2b from "./image/d2b.png";
import d3a from "./image/d3a.png";
import d3b from "./image/d3b.png";
import licenta1 from "./image/licenta1.png";
import licenta2 from "./image/licenta2.png";
import licenta3 from "./image/licenta3.png";
import licenta4 from "./image/licenta4.png";
import licenta5 from "./image/licenta5.png";
import licenta8 from "./image/licenta8.png";
import licenta9 from "./image/licenta9.png";
import licenta11 from "./image/licenta11.png";
import licenta13 from "./image/licenta13.png";
import licenta14 from "./image/licenta14.png";
import licenta15 from "./image/licenta15.png";
import licenta16 from "./image/licenta16.png";
import licenta17 from "./image/licenta17.png";
import licenta18 from "./image/licenta18.png";
import licenta19 from "./image/licenta19.png";
import licenta20 from "./image/licenta20.png";
import licenta21 from "./image/licenta21.png";
import licenta22 from "./image/licenta22.png";
import licenta23 from "./image/licenta23.png";
import licenta24 from "./image/licenta24.png";
import licenta25 from "./image/licenta25.png";
import licenta26 from "./image/licenta26.png";
import licenta27 from "./image/licenta27.png";
import licenta28 from "./image/licenta28.png";
import licenta29 from "./image/licenta29.png";
import licenta30 from "./image/licenta30.png";
import licenta31 from "./image/licenta31.png";
import licenta32 from "./image/licenta32.png";
import licenta33 from "./image/licenta33.png";
import licenta34 from "./image/licenta34.png";
import licenta36 from "./image/licenta36.png";

const pages = [
  {
    id: -2,
    isImage: false,
    text: (
      <div id="_idContainer001" className="_idGenObjectStyleOverride-1"></div>
    ),
  },
  {
    id: -1,
    isImage: true,
    image: (
      <div id="_idContainer001" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={cover} alt="" />
      </div>
    ),
  },
  {
    id: 0,
    title: "big",
    isImage: false,
    text: (
      <div id="_idContainer002" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">big</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i feel big,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">walk over houses big,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">touch treetops big,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            buildings shrink, humans shrink and get blinked away into
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;dots,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the spaces between my fingers house spiders and people and
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;all kinds of insects,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i breathe in</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            skies, white clouds, rain clouds and wind gusts,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the steps that i take span across</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            neighborhoods, cities and chains of highways,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">with open arms for the world to come in</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and hide underneath my limbs,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">open skin for trees to grow roots in,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">for bats to sleep in,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">ghosts to get in</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and out whenever they want,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            get lost in the skin labyrinths that are my palms and feet
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            tremors come out of the ground when i walk,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">planets seem so much closer,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">jump off the ground into a</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">pool of black that just grows,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">get your hands wet,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">get your feet wet,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            water that sticks to your skin and crawls over across your
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;chest,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it&apos;s things like this that don&apos;t care about magnitude,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">size,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">capacity or volume,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">molecules flood grooves in my skin,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">get carried away and</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">navigate channels on maps drawn from</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">layers in the making.</span>
        </p>
      </div>
    ),
  },
  {
    id: 1,
    isImage: true,
    image: (
      <div id="_idContainer001" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta13} alt="" />
      </div>
    ),
  },
  {
    id: 2,
    title: "function",
    isImage: false,
    text: (
      <div id="_idContainer003" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">function</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">brain noise, a</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">crowded tram arriving at 7:51</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a couple hundred lungs pulling in and out the same air,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">thinning oxygen levels,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">electrical impulses spark in</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">cables, flesh and electronic circuitry,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            polyester garments rubbing against one another,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            fingers sliding on glass screens rippling currents and waves,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            air currents seeping underneath and in between sliding public
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;transport doors
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            things get in place and things get out of place,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            gears shift, axels shift positions, bodies rotate and permutate,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            clasping gear teeth in a skittering mechanism
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            lungs inflate, joints rotate, arteries pump fluid to extremities,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i function,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i input,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the tram has arrived to its destination.</span>
        </p>
      </div>
    ),
  },
  {
    id: 3,

    isImage: true,
    image: (
      <div id="_idContainer004" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta15} alt="" />
      </div>
    ),
  },
  {
    id: 4,
    title: "space shuttle-d",
    isImage: false,
    text: (
      <div id="_idContainer005" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">space shuttle-d</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">my</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            skin; a shell that&apos;s been placed in between
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">ever surrounding silence,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            inside and outside staring into each other&apos;s eyes,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">,my skin,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the only living thing that i see in a place with
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">no man&apos;s noise,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a place with</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            animals and things that hide hidden deep into the trees,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a gust of wind that sounds like the drum of a heart,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a passing car with no driver to be found at the wheel of the
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;world,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            surrendering to the stillness of a room that just seems like it
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;breathes,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            grainy walls; a rugged leather membrane of the thing that{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;&#9;i&apos;m in,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            sometimes nothing makes sense but surrender
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">to an endless sized world </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            that looks effortlessly deep inside your whole being.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">my </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">skin,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">good enough to hold the innards in,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            but doesn&apos;t do much when i&apos;m floating to the atmosphere,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">outside the dirt and into</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">cold air seeping into</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">pores and clothes and eye and ear holes,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">turning into dust</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">has never been this strange.</span>
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "online body language",
    isImage: false,
    text: (
      <div id="_idContainer006" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">online body language</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">jump start my monomyth,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">out of the womb and into</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">key performance indicators</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">out of the family unit and into the</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">unity of workplace cooperation,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">out of the subway and into</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">pedestrian traffic,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">out of my skin and into my skin.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            out of the box of marshmallow flavored frosted flakes breakfast
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;cereal
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and into my mouth, between my teeth, on my tongue, down my
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;throat,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">public transport sucks,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            my keyboard-pressing thought bot fingers are but appendages to{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;&#9;my id badge.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">out of online body language and</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">unsatisfactory profile pictures</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            into an interminable amount of high definition pornography{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &#9;&#9;&#9;&#9;&#9;&#9;streaming services,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">out of supply and into demand,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            out of my friend&apos;s daily yoga sessions that help her maintain
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            an emotional balance in an otherwise stressful environment.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">coming up for air,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and yet when it happens it doesn’t feel like you did anything to{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;make it happen,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            air that has found its way in my lungs on its own,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">electrochemical i,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">second sight through floating eye-motes,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            for a minute there i was out of the loop.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 6,
    isImage: true,
    image: (
      <div id="_idContainer007" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={d1a} alt="" />
      </div>
    ),
  },
  {
    id: 7,
    isImage: true,
    image: (
      <div id="_idContainer007" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={d1b} alt="" />
      </div>
    ),
  },
  {
    id: 8,
    title: "Hundred Waters",
    isImage: false,
    text: (
      <div id="_idContainer008" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">Hundred Waters</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">wasting precious time</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">thinking about wasting my life,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when does it come?</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the release i&apos;ve been waiting for so long,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the end of the line.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            when time seems to either run amok or crawl
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">where do you find yourself while</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            your footprints seem to be all around the room and walls,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            places in which i don&apos;t remember being,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            places i&apos;ve heaved through with no trace of my own.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">making plans for the future,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            setting up a tight schedule to increase productivity,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            exercising synergy and teamwork capabilities,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">falling into crippling depression,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">drifting on the streets, not walking,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            what happens when nothing goes according to plan?
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            what do you get when the only thing you seem to get is{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;petrified.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            searching for walls in which not to bump,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            staring intensely at screens and the bottom of glasses,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&apos;i thought i had it in me.&apos;</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">taking deep looks in the mirror</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            until the body in it is just somebody else&apos;s,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            scarred skin and chipped teeth don&apos;t mean a thing,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the hundred waters that will wash you at the Heaven&apos;s gates
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;are only tears,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">don&apos;t hold them in.</span>
        </p>
      </div>
    ),
  },
  {
    id: 9,
    isImage: true,
    image: (
      <div id="_idContainer009" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta1} alt="" />
      </div>
    ),
  },
  {
    id: 10,
    title: "safe distance",
    isImage: false,
    text: (
      <div id="_idContainer010" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">safe distance</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">my body isn&apos;t my body anymore.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">there&apos;s nothing to do now but wait</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            for things to fall down from the sky and directly into place,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">blood to pump in the veins,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the mind and the soul to get into the same position,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like printing plates,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like indigo,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            there&apos;s nothing to do to it other than to put it in the sun to{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;&#9;dry out,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            let it get old, chipped and eventually die,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            leave it out for other living things to make use of it,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            set it on the beach and watch the sea take it away,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            let the sun scorch it like a hard day of work,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            let fish and jellyfish eat its skin and meat,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">let it be an offering to the world.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">i want to put it to good use,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            nourish it so it might grow out of its shell,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            give my mind to it so it might eat it for the protein
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and squeeze the soul into juice for it to drink.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">my body has the same address as me.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and it walks a lot, i&apos;ve seen it, and it never seems lost,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and yet all i&apos;ve seen it do is</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            pass through walls and furniture, though always keeping a
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">safe distance.</span>
        </p>
      </div>
    ),
  },
  {
    id: 11,
    title: "justify",
    isImage: false,
    text: (
      <div id="_idContainer011" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">justify</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">cold spring nights,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">nothing ever comes out simple,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">trying to justify</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">self-destructive situations - </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">building bridges and tearing them down,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            counting the digits on a bomb that you&apos;ve stuck to your own
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;chest.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">trial and error,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">noting down the things that change,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">trial and terror</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            when all of these seem impossibly incomprehensible,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">building bodies out of building blocks,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">you&apos;re</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">twisting veins and arteries</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            out of the strands of hair that get stuck in your teeth
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when you bite</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            always looking to feed an always hungry mouth,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like an always angry child,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like a moth to a flame</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            you&apos;re jumping off the Empire State Building
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            with nothing to hold on to but her waist.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 12,
    title: "input-output saturation",
    isImage: false,
    text: (
      <div id="_idContainer012" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">input-output saturation</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">faces blend into one another,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">floors and walls blend into one another.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">while solid things are</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            partitioned into easily manageable sizes:
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">floor spaces into clothing stores,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">home &amp; kitchen appliances emporiums,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">outlet shops and dry cleaning services,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">human beings work the other way around,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">you put the parts together to make a</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            functioning system of interconnected pieces,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">that input and output.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            hard to distinguish voices out of throats from
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">voices out of speakers,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a string of bytes compiled into a string of
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">electronic signals,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            facial features on faces on screens translated through{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a matrix of light-emitting diodes,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            glowing skin labyrinth simulating breath();
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">faces blend into one another,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">colored screens blend into</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">shapeless haze,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">some things are solid,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">some I</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">hallucinate,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">half muttered overtones,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">in crowd management methods,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">copy-paste behavior syndrome, i&apos;m</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">getting mixed signals.</span>
        </p>
      </div>
    ),
  },
  {
    id: 13,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer013" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta8} alt="" />
      </div>
    ),
  },
  {
    id: 14,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer014" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta34} alt="" />
      </div>
    ),
  },
  {
    id: 15,
    title: "it'slike",
    isImage: false,
    text: (
      <div id="_idContainer015" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">it&apos;slike</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            sitting in my chair and it feels like i&apos;m speeding through the
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;atmosphere,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            walking on the street and it&apos;s like i&apos;m sinking hundreds
            of miles &#9;&#9;&#9;&#9;&#9;&#9;&#9;with every step,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">walls and windows seem to</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">e-va-po-rate.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &apos;you forgot your keys&apos;, she says as i&apos;m about to go
            out the door,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            we glance at each other and it&apos;s like i won&apos;t need those
            anymore -
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">rushing to the</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">elevator, into the street, to the</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">bus station, getting on the bus</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like i&apos;m leaping fucking Mt. Himalaya, getting
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">bent like a piece of thick wire,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            other cars passing by seem to crash and crumple like paper
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and i&apos;m standing there, in the bus, with every breath i take it
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;feels like
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;m breathing in the Earth&apos;s whole atmosphere in gulps.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i take a big one this time,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the kind that goes beyond just your lungs
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and gets felt all the way to the fingertips.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">veins seem to bubble and boil,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">wrists and knees tighten up,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            as i exhale, it&apos;s like i&apos;m pouring my face in a cold
            bucket,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it&apos;s like the knots that keep you rigid while awake get untied,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">skin seems to turn into melting glass,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and it takes just a blink</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and i&apos;m back at her apartment,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            door frame and door knob as rigid as ever.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and it&apos;s that moment when she says it:
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &apos;I&apos;m sorry, I just think that maybe we should start seeing
            other &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;people.&apos;
          </span>
        </p>
      </div>
    ),
  },

  {
    id: 16,
    title: "Just give me more time",
    isImage: false,
    text: (
      <div id="_idContainer016" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">Just give me more time</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">s l o w d o w n,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            you&apos;ve been racing and aching to put
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">everything in its right place</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and that&apos;s nice, and it&apos;s fine,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">but some things and some people</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            are not made to be placed or assigned to a space,
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            there are some that just blow up bookstands and walls
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">or just seep through the drains</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&apos;cause some things and some people</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">don&apos;t have their own stillness</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and that&apos;s only the way that they are.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and i&apos;ve been dust and steam and serpents all the same,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and you&apos;ve been wind and flames and ice that never melts,
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and isn&apos;t it fun to jump through hoops or pass through walls
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">or turn into rain when you feel clouded?</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">i guess what i&apos;m trying to say is</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">breathe in,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            there&apos;s a special kind of silence that can only be perceived
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">once you&apos;re overwhelmed,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            but it&apos;s a good kind of silence and that&apos;s a good kind of
            tired,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            when even the dark circles under your eyes feel like smiling,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like calluses filled with pride.
            <br />
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            so, what it just feels like it has to be said is:
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">breathe in (and out, eventually),</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">time will fill the holes in your skin</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like fresh water</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and time will be the one to clean me up</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">whenever i feel like a mess.</span>
        </p>
      </div>
    ),
  },
  {
    id: 17,
    title: "",
    isImage: true,
    image: (
      <div id="_idContainer017" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta11} alt="" />
      </div>
    ),
  },
  {
    id: 18,
    title: "minimum",
    isImage: false,
    text: (
      <div id="_idContainer018" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">minimum</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">can i</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">heat up and evaporate,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            have the winds blast me into a shower of particles
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like warm sand in the sun, like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">embers floating away to their end,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">scattered across the world so i can</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            stick to people&apos;s skin and be carried away on to foreign
            shores,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            get taken along, be misplaced, lost and then found again,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            in pockets of coats and backpacks thrown somewhere in{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &#9;&#9;&#9;&#9;&#9;&#9;somebody&apos;s room,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i could turn into seeds and grow into plants to put on{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;the windowsill</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;d turn into things you thought you lost,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            things you didn&apos;t know you have and lucky coins found on the
            pavement,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            chips of glass that gleam when you look at the ground,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            beetle shells and motes inside your eyes.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">specks of sand in the lines of my palm,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            might be people who chose to dissolve themselves,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            growing roots and leaves in pots on the balcony,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">people who chose to rest,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;m waiting for a sunny day where the air is warm
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and the wind is out there</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">so i can do the same.</span>
        </p>
      </div>
    ),
  },
  {
    id: 19,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer019" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta36} alt="" />
      </div>
    ),
  },
  {
    id: 20,
    title: "hanging by a thread",
    isImage: false,
    text: (
      <div id="_idContainer020" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">hanging by a thread</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">monday, tuesday, wednesday night,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">birds of a feather,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">closure and the act of letting go,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things you wish would come together,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">days and nights spent</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">breathing in front of computer screens;</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            alive and active in the virtual space of no consequence.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">thursday, friday, saturday</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">alcohol and deeply embedded inhibitions,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            not exactly high, more like numb beyond comprehension;
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            is it running away from things or is it just
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            hiding the ones that you&apos;re supposed to hide?
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i don&apos;t know.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            what is there to do besides wander the night?
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            nice shirts, nice looks, nice footwear too,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">your skin constricting between</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">an ever-growing space inside your body</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and a reverberating room of grainy imagery
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">layers condense into paper-thick skin,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">facial features blur in image noise.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">low res quality exit.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;ve lived inside my head for years and years
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">falling into shape like liquid,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">warping the paper like diluted paint,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            an always trembling house in which you try to sleep
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like certainty&apos;s a thing i don&apos;t know how to keep.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like all the things we have are always</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">hanging by a thread.</span>
        </p>
      </div>
    ),
  },
  {
    id: 21,
    title: "seas",
    isImage: false,
    text: (
      <div id="_idContainer021" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">seas</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">laugh with me,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">show me how to make things simple</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">in the ways only you know</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when i&apos;m cast away,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">seas of insecurities and digits </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">whirlwinding into my throat</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">run away</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">to places with no names</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            where we&apos;ll be sinking into tall wheat fields and clay,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a sea of grain to drown out the ear drums
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            to sting our hands with weeds and branches
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">whirring washing machines,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            spinning computer fans and buzzing TV screens,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i cut and paste myself into magazines,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i twist my neck looking for spots in the ceiling where i can jump
            straight into,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            you&apos;ve got your sounds that sum you up,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the wind your hair, the clicking of your camera,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the zipping of your jacket and the sound of springing steps on
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;the asphalt,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">we&apos;re</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">walking round the puddles on the street,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">we&apos;re</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            looking at each other from across the gym
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i may be on solid ground but i am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">swimming in your eyes.</span>
        </p>
      </div>
    ),
  },
  {
    id: 22,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer022" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta16} alt="" />
      </div>
    ),
  },
  {
    id: 23,
    title: "living, loving a.a.t.o.t.",
    isImage: false,
    text: (
      <div id="_idContainer023" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">living, loving a.a.t.o.t.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">air,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am taking it in and out of my lungs,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            words, putting them in and out of my thoughts,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            men and women and elders and children and we talk,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">pieces of paper and pencils and pens,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">keyboards and keys and computer screens,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">words come out and they&apos;re</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            full of the things that i&apos;ve thought and said,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and it&apos;s late,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like the things one can search for at 4 am are
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">closure, composure and a way to end</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the cycle of</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">day in - day out - paralyzing sleep.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">apartment blocks slumber and breathe</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like tired old men with grainy skin,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            buzzing tv noises fade into the sound of steps
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            into the sound of car tires that roll on the asphalt and melt,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            breathe in walls and leaves and fences and entire streets,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i exhale car smoke and sighs through the teeth,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">it&apos;s getting strange,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like the imprints of my shoes on the pavement and how
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it kinda feels like i&apos;m sinking in it,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            not exactly sure if i&apos;m sleeping or being paralyzed by it,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            but i&apos;m dreaming and the days i dream about are the ones
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when i&apos;m capable of living, loving</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and all the other things associated with overcoming fear,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i guess that&apos;s when you ask yourself the question:
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">life, am i living it?</span>
        </p>
      </div>
    ),
  },
  {
    id: 24,
    title: "m_rn_ngs",
    isImage: false,
    text: (
      <div id="_idContainer024" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">m_rn_ngs</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">teeth chipping,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">skin wrinkling,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">mornings disappearing from sight.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">new music, old rhythms,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            same body in which you usually spend your time.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;ve been feeling fast, i&apos;ve been feeling slow,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            actually a bit hungry while i&apos;m writing this,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;ve been feeling ready for a while to jump out the window and{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">swallow all the cars on the street.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">shoulders getting kinda heavy,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            things i&apos;ve been avoiding start to feel like burdens,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">days turn into schedules,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and i seem to forget in which book i had written the path for{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the rest of the trip.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">waking up in the m_rn_ng,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            with a feeling that i won&apos;t remember what&apos;s happening
            today,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            sit in my chair like i know what i&apos;m doing,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things around me keep doing their thing:</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">paint on the walls keeps flaking,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">carpet and desk get dusty,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            power outlets and screens heat up when turned on.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and i keep doing my thing too,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">counting days left,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">breathing air in my lungs,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            turning my head slightly to the left when watching television.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            girls in commercials keep getting prettier and prettier,
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">good for them :)</span>
        </p>
      </div>
    ),
  },
  {
    id: 25,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer025" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta20} alt="" />
      </div>
    ),
  },
  {
    id: 26,
    title: "milk",
    isImage: false,
    text: (
      <div id="_idContainer026" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">milk</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">white sunlight,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the kind that bounces off the asphalt, straight into
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">your eyeholes,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">kinda smells like the world is ending,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            small industrial town where the weather just
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">collapses on,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">be it</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">rain, wind, whirlpool whirlwind typhoon,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">it just flows and overflows,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            cars, men, women, factories and schools and medical facilities,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a spot of oil on the asphalt,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a moment of hesitation</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            immediately before we&apos;re all flooded with
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">wind, rain, white sunlight</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            coming down from the heaven&apos;s pulp itself
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            down through glass window ceilings and onto
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">tiled floors.</span>
        </p>
      </div>
    ),
  },
  {
    id: 27,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer027" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta22} alt="" />
      </div>
    ),
  },
  {
    id: 28,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer028" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta2} alt="" />
      </div>
    ),
  },
  {
    id: 29,
    title: "on point",
    isImage: false,
    text: (
      <div id="_idContainer029" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">on point</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">trying to be more of myself</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when all layers of skin seem to be</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">caving in</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">on fine bed sheets and beds</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and furniture and precious smiles</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">of joy and living-life-ness,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            anxiety&apos;s an always exploding time bomb
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and i am on point.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">pressure, genuine human interaction</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and the diffuse line in between,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            nodding and approval and boosts of serotonin,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the search for kindred spirits and what does that even -
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &apos;that&apos;s when i reach for my revolver&apos; -
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a song heard in a pub,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            getting blown away from the face of the planet,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the moment you can&apos;t even tell if you&apos;re alone or not,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            if the person next to you is talking or if they&apos;re just
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">mumbling away thin, transparent words</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            that slip between the molecules and fade,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the cord, the plug, the socket,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the spirit,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the way you can&apos;t even tell if it&apos;s short-circuiting or
            not.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            silence and all of its possible outcomes,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the shifts in mood and the skimming of our hands against
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;one another’s,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i wish i knew what to say</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">to make sense of what&apos;s going on.</span>
        </p>
      </div>
    ),
  },
  {
    id: 30,
    title: "signals in the night",
    isImage: false,
    text: (
      <div id="_idContainer030" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">signals in the night</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">opening of the mouth,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            cold air gets in and fills the space in my oral cavity,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            cold air gets in from a street that is full of nothing else but it.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the sound of a nearby dog barking makes it vibrate and{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;flicker.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the sound of my footsteps,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">inundated by it,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            what&apos;s left of it reverberating off walls and fences,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">dissipating into the atmosphere.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            I reach streets with still trees and power lines
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">entangling,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">houses hum and give off body heat,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            while wind envelops my hands and fingers,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            every now and then a car passes by and the hum of its engine
            &#9;&#9;&#9;&#9;&#9;&#9;fades into distance,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            every now and then i hear somebody else&apos;s footsteps
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and sometimes it matches the rhythm of mine
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">until the sound</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">dissolves.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            I reach the mouth of my apartment block and climb inside,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">listen to the buzzing elevator,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">open the door to my home,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">its insides</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">look like my insides,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">open the window,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            cold air gets in and fills the space in the room,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            with no one to hear them words get swallowed by it.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 31,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer031" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta21} alt="" />
      </div>
    ),
  },
  {
    id: 32,
    title: "utility poles",
    isImage: false,
    text: (
      <div id="_idContainer032" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">utility poles</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">mom and dad</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            growing up like corn stalks in dry july summer sun
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            in houses with brothers and dried up dirt on the roads,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            barefoot with blades of grass in between their fingers and toes
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and gusts of wind that told them</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            stories about adult men and women doing business and{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;breathing city air.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">1970-something,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i can never picture winter when i think about the past,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">skies must have been the same</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">shades of blue,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">children&apos;s soles must have been</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">thicker,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">their hair wilder and their noses</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">runnier.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">2000-something,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;ve got chinese sneakers in my feet,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">there is sun in the sky, shining down on</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the pavement and car hoods and tops of apartment blocks,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            blades of grass piercing out of cracks in the sidewalk,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            carpet beating racks doubling as goalposts,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">mom and dad working</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">asphalt jobs in leather shoes,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">raised asphalt kids under streetlights</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">hid what&apos;s left of youth under,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">utility poles.</span>
        </p>
      </div>
    ),
  },
  {
    id: 33,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer033" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta30} alt="" />
      </div>
    ),
  },
  {
    id: 34,
    title: "in the dark",
    isImage: false,
    text: (
      <div id="_idContainer034" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT CharOverride-2">in the dark</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">all i ever do is escape,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            thoughts of hips and screens and bottles,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">things to put into your body</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">that make you hallucinate,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">red skin in a dark room,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            fingers melting into keyboard keys and plastic pens,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">i&apos;m nowhere, really,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">i&apos;m nothing to be seen or heard,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">words on a</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            telephone screen shriveling under your breath,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            undecipherable lines of text in an always changing{" "}
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">language,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            i am wherever there&apos;s light to be reflected on my hands,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            wherever there&apos;s things to bounce back into my iris.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">i</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            treasure moments when there is something like your skin
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            to remind me that i&apos;m still present when i find myself in the
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;dark,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">with bed sheets that grab at my limbs</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">and a sinking sensation</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">in the back of my skull,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">words are just illusions</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            and sound&apos;s a thing that doesn&apos;t really matter
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">in the dark,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">background noise in deep sleep nights,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">blowing wind and tires on the asphalt,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">grains of sand into a conch shell skull,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">cleared away by the sound of</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">your cat meowing at 4 in the morning.</span>
        </p>
      </div>
    ),
  },
  {
    id: 35,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer035" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta18} alt="" />
      </div>
    ),
  },
  {
    id: 36,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer036" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta33} alt="" />
      </div>
    ),
  },
  {
    id: 37,
    title: "shell-ter",
    isImage: false,
    text: (
      <div id="_idContainer037" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">shell-ter</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&apos;i want to hide you.&apos;</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            never in my life have i empathized with another&apos;s words that
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;much.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            never in my life has the thing i&apos;ve been feeling has been put
            into words this clearly,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">hide you,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            get you, take you, steal you again and again
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and put you in a place to end this cycle forever -
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            either shut all eyes or shut all lights forever,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            either cut all hands or throw away all of the keys for eternity.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            shadows and shade and covers that block out the sun,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            shriveling muscles and bones like burnt matches, a{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;discarded shell,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            an open back, ripeness in the form of skin to be
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            pierced and torn apart like the flesh of a -
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            always searching for things to shelter me with
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">whatever my mind makes out of them,</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the burden of being and the fear of breaking your back,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and the fear of losing your arms,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and the fear of having your legs stop working and collapse,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am thousands of kilometers away</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            but the things you&apos;ve once buried stay hidden deep in the dirt{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&#9;&#9;&#9;&#9;&#9;&#9;&#9;of my flesh,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like an ever breathing garden i am always
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            branches and leaves and sunlight and shade all the same.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 38,
    title: "physics problem",
    isImage: false,
    text: (
      <div id="_idContainer038" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">physics problem</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">17.3 minutes until I&apos;m part of a</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            high-speed fifth gear prolonged damage impact,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">flames transcend day-to-day themes like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            shape and color and electro-chemical reactions in the brain,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">high-density welding-torch fire I am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">blown away under</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">self-imposed safety measures,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">sometimes</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">hitting something really hard</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">makes you feel like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a carbon-based calcium sodium chloride electrical powered piston,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">I</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">become things through</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">violent discharges,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">what&apos;s a path when you&apos;re</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            drilling through obsidian and thick fog, one with the
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">ever-shifting entropy</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">of doings and expectations,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">I am the</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            lead concrete wall and the colliding object,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">I am pieces,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">break pieces</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">physics problems.</span>
        </p>
      </div>
    ),
  },
  {
    id: 39,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer039" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta23} alt="" />
      </div>
    ),
  },
  {
    id: 40,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer040" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta3} alt="" />
      </div>
    ),
  },
  {
    id: 41,
    title: "million",
    isImage: false,
    text: (
      <div id="_idContainer041" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">million</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            poured asphalt on a horizontal plane on an intersection with
            &#9;reinforced concrete structures, i am
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            corners, crossroads, street signs and stoplights, i am
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            things put into place in a 90 degrees angle, yet i am
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">crumpled paper blowing in the wind,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            grass growing in the cracks in the pavement,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            oil spots and dents in the ground that you walk on, i&apos;m
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            scenes and scenery into light waves and retinas, electrical{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &#9;&#9;&#9;&#9;&#9;&#9;impulses to the -
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i am straight poles and lamp posts and street signs and signposts,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            electricity, dial-up, ethernet cables that twist underground,{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            pipes and pipelines and faucets and valves that spin and are
            &#9;spun
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i am gloves that are black and gray and have holes covering hands
            that grab levers and
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">handles and door knobs and doors,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">rust particles on concrete walls, i</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">age, deteriorate, crumble and force</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things in and out of positions</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            of action, inaction and complete connection
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i am</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            sunsets with red yellow crimson charcoal suns that breathe in
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            air and all kinds of exhaustion fumes, i am
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            steam inside and outside of machine lungs, i am
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            wrenches and bolts twisted into metal plates, i&apos;m
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            an LED light inside a million dots and particles that change their
            colors.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 42,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer042" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta9} alt="" />
      </div>
    ),
  },
  {
    id: 43,
    title: "train station",
    isImage: false,
    text: (
      <div id="_idContainer043" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">train station</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">is is your fault or mine</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            because there is a chance that it&apos;s me who is to blame
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and i definitely don&apos;t want that.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            there&apos;s a chance that the things that i feel
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            might turn the one i&apos;m making you out to be into something
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;that you&apos;re not,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">words made out of pieces that</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">disassemble,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like train wagons and luggage bags and people in the train station,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a timetable of whenever and wherever diluting into one another,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the act of letting go and the following</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            hollowness in the very next train to arrive,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            forgetfulness and how i&apos;ve used it so many times
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">to go back to square one.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">square one, the place to go to</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            move out of the way of things that come in your direction,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            to impart pieces of self-targeted wisdom,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            to sit in the comfort of a shape with only four sides.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            solitude sometimes feel like being in the middle of a seemingly
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;endless field
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            but it&apos;s not the sun or the walking that&apos;s the issue,
            it&apos;s just
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            there&apos;s so many options of where to go
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            so many places in which to cut your skin and stub your toes{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">that it makes it hard to leave.</span>
        </p>
      </div>
    ),
  },
  {
    id: 44,
    title: "steam",
    isImage: false,
    text: (
      <div id="_idContainer044" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">steam</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            losing myself in the follicles of hair on your face and hands,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i just</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            drip down the sides of your ribs and waist
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">in a</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">cold yellow sun that&apos;s paralyzing</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the steam coming out of your skin.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">losing myself in spaces that grow</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">in between layers of cloth,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">in between fingers and computer keys,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            in between bed sheets and beds and a room that feels like
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">it&apos;s always expanding,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">float into the atmosphere,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            all i wanted was for things to stay together where i put them,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            all i hoped was that i&apos;d learned enough to do so and just not
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">evaporate again,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it&apos;s parts and particles of my hands floating away
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">that i&apos;m always looking at.</span>
        </p>
      </div>
    ),
  },
  {
    id: 45,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer045" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta24} alt="" />
      </div>
    ),
  },
  {
    id: 46,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer046" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta25} alt="" />
      </div>
    ),
  },
  {
    id: 47,
    title: "i forget about it until the next time it happens",
    isImage: false,
    text: (
      <div id="_idContainer047" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            i forget about it until the next time it happens
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">2:16 am,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i’m exuding heat in an Uber on my way home,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            my friends, they’re a couple, holding each other’s hands on{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;the backseat,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">we get on the boulevard,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            trees’ leaves get in the way of the lights and
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            shadows pass over the car and cover our faces for a couple of
            seconds,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i think we all forget who’s who for a couple of seconds,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i find my hands and face only after they get lit by lamp post light,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i think i find</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            myself only after being shown what i look like.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the driver takes his turns carefully guided by his phone’s{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &#9;&#9;&#9;&#9;&#9;&#9;navigation system.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">i say goodbye to my friends and get off,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the street gobbles me up in its shadowy stomach.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            there’s no light to fall on my skin and help me find out where my
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;body is,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i rustle in the dark for the keys in my backpack,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">it seems like if</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            these keys fit this lock then this is where i should be for the
            moment
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">or at least</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            this is the place that has found me so far.
          </span>
        </p>
        <b></b>
        <p className="Basic-Paragraph">
          <span className="tEXT">it seems like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">who i am is more times than not</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            just a series of things that have found me
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">or in which i have, knowingly or not</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">fallen into place,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            open the door and find i’d forgotten to turn the lights off,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            spots of yellowish light fall on and trace the shape of my
            cheekbones.{" "}
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 48,
    title: "to hurricanes",
    isImage: false,
    text: (
      <div id="_idContainer048" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">to hurricanes</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            all the things that i&apos;ve done and the people i&apos;ve been,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and the ones that i&apos;ll do and the people i&apos;ll be,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i guess in their grand scheme</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">it&apos;s the small things that matter</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and the one that&apos;s the most important right now
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">is that i&apos;m holding your hand.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            if we&apos;re to waste ourselves in either time or space,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            be it days together or borders in between,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            if there&apos;s no place to go to stop the track of time
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and no thing to fashion to speak each other&apos;s tongue,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            to say that at least we&apos;ve walked a piece of this road together
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">is enough.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">Earth spins and turns in her sleep,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">days come and go wherever you are.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            men who&apos;ve i&apos;ve been look back to{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">these summers of ours,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">rocks into sand into waves into suns -</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">up until we drifted apart</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">you held me.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">&quot;days will come</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">with bad weather,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">days will come with hurricanes -</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the house is going to be torn apart,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the first floor is going to get</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">flooded</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">rocks into sand into waves into suns -</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;ll hold you.&quot;</span>
        </p>
      </div>
    ),
  },
  {
    id: 49,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer049" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta32} alt="" />
      </div>
    ),
  },
  {
    id: 50,
    title: "things we’ve forgotten about",
    isImage: false,
    text: (
      <div id="_idContainer050" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            things we’ve forgotten about
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the comfort of cold bed sheets,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the smell of the city in the evening,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the noise of crickets in every yard at night;
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m growing branches and twigs,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m breathing</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            air that&apos;s been in lungs and factories and cars and on
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">people&apos;s lips,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m seeing</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">clouds that go by that never come back,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">men and women that never come back,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">mothers, fathers, sisters, brothers,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">painted walls and fur and feathers,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">one by one turning into</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things that we&apos;ve forgotten about.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">empty greys above the skyline</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            that wait for you to fill them up with whatever&apos;s in your head,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">wrinkled apartment blocks</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">that breathe deeply in and out,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            beige walls and door frames and everything between,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            rusty locks and door knobs and fences on the street
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">that stay still</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            while you switch and twitch and turn in your sleep,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            while you grow roots on foreign soil and into other people&apos;s
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;dreams,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">you&apos;ll always have</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">cotton bed sheets, exhaustion fumes,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and that awful shade of beige that used to be in every room you
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;walked into.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 51,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer051" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta26} alt="" />
      </div>
    ),
  },
  {
    id: 52,
    title: "youth is full of lighter fluid",
    isImage: false,
    text: (
      <div id="_idContainer052" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            youth is full of lighter fluid
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">there must be more to it</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">than rummaging through sea shells,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">more than</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            sharpening and grinding teeth for the rest of your life,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">there must be something missing,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">something that&apos;ll</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">fit, snap, click right into place,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            some kind of red, blue, orange, neon green thing
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            or circle-square-deus ex machina that&apos;ll break the slow-motion
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &apos;cause you&apos;re at that age when you should be blowing up,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            lighting up the atmosphere with your pretty mouth,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            you should be the one showing these handsome devils
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            who&apos;s the real deal, the fine blended cut,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the reason it&apos;s really worth paying those 5 extra bucks.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">why smile if you&apos;re not biting,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">why be gorgeous without fucking,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">why be young if you&apos;re not</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">splattering your name on all the walls,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &apos;cause if you&apos;ve got fire in your eyes
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">then youth is full of lighter fluid.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">you would illuminate the streets,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">brightening the atmosphere</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">with your pretty eyes and teeth.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">should, would, possibly, probably,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            words that mean nothing when you feel like you&apos;ve skipped past
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a period of screaming your name at the top of your lungs,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it&apos;s 6:45, you might want to hurry up if you don&apos;t want to
            be late,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            you know how crowded buses get in the morning.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 53,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer053" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta27} alt="" />
      </div>
    ),
  },
  {
    id: 54,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer054" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta29} alt="" />
      </div>
    ),
  },
  {
    id: 55,
    title: "treadmill",
    isImage: false,
    text: (
      <div id="_idContainer055" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT CharOverride-2">treadmill</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            typing away numbly in front of lcd screens,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            a set of bones and muscles covered in skin,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">hours and sleep cycles go by so fast</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">but days stay the same,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">unmoved and unmovable, the same faces</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">in the buses, cars, subways and trains,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            expecting a moment of perfect clarity in the fog of
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            burning rubber, automobiles and cigarette smoke -{" "}
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">running away, on a treadmill,</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            heart rate, inclination and speed to the optimum levels
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            to feel like you&apos;re escaping yourself through spiritual
            enlightenment, connecting to the astral plane
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            and in the same time efficiently burning calories.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">the balance between</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            a good amount of work and a good amount of team-building
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;activities,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            a good amount of friends and a good amount of time for yourself{" "}
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            in which to disect your own personality and the personalities of
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;said friends,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            a good amount of passion and an even better amount of wondering why
            it&apos;s not working out.
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">running on a treadmill.</span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            my friend never does it and he&apos;s always lean,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            it&apos;s all about the hormones, the testosterone,
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">
            waking up in the morning wondering how i should properly regulate my
            cortisol
          </span>
        </p>
        <p className="Basic-Paragraph ParaOverride-1">
          <span className="tEXT">levels.</span>
        </p>
      </div>
    ),
  },
  {
    id: 56,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer056" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={d2a} alt="" />
      </div>
    ),
  },
  {
    id: 57,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer056" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={d2b} alt="" />
      </div>
    ),
  },
  {
    id: 58,
    title: "violence",
    isImage: false,
    text: (
      <div id="_idContainer057" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">violence</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">violent winds sweeping the nation,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">nuclear warheads in silos underground,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">ICBM rockets piercing the sky like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">swift arrows shot by blood pumping,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">fist clenching, </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">bone cracking</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">furious limbs,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">an</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">uppercut to the solar plexus, a</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">knee to the bridge of the nose,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">blood in the air and on the floor like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            typhoon hurricane rains that spoil the crops
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and make animals run loose,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">my shout, a banshee&apos;s scream,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">my wail, a sudden cascade of untied ends</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            of strings over which to trip and stub my feet and break my legs.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it seeps into the way i curve my letters when i write
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            into the way i slide my hands into my shirts,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            overflows into my speech and when i talk i{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">bark like a hound and</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when i stand still </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the hair on my skin cowers like wheat fields in a storm.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 59,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer058" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta17} alt="" />
      </div>
    ),
  },
  {
    id: 60,
    title: "against things I don’t understand",
    isImage: false,
    text: (
      <div id="_idContainer059" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">
            against things I don’t understand
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">Enter</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a series of events that is making me feel like
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            an open wound on the planet&apos;s surface,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            one of those ways of the beating of the heart that makes you
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;think you&apos;re
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">Levitating</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            slightly above the surface of the ground i&apos;m
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">open exposed like circuit boards,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like intestines,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like electronic insides,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">skin, carcass, paneling,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            differences blur when you&apos;re not in it,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i look at things and things look back like
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">electric shocks,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things that burn and singe,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">what the f-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">autumn sunlight fades all my insides,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the kind that doesn&apos;t burn and instead just
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">floods everything it gets into,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">open, closed,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">not sure if it matters,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">interactions just seem to disappear</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and words fall dry on the ground,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i wish i had the skill to patch things up
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">but right now it just feels like</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">everything i say or do just</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">disintegrates like dried up leaves under</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">indifferent sunlight.</span>
        </p>
      </div>
    ),
  },
  {
    id: 61,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer060" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta28} alt="" />
      </div>
    ),
  },
  {
    id: 62,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer061" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta14} alt="" />
      </div>
    ),
  },
  {
    id: 63,
    title: "weightless",
    isImage: false,
    text: (
      <div id="_idContainer062" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">weightless</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&quot;why do i...</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">feel so sleepless all the time?&quot;</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            he asked himself while counting his fingers,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">&quot;what a strange sensation,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            as if i&apos;m dust and i&apos;m scattered,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it&apos;s like i&apos;m here, but not really,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">myself, but not quite&quot;</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i guess it&apos;s normal to doubt yourself every once in a while,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            yet what do you do when you&apos;ve been weightless for so much
            time,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">always tall yet forever filling gaps,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            always loud yet forever walking in silence,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">formless, contour-less,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">liquid.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">words are fine, words are</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">good to speak and shout and waste,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            yet there are these times when everything you are just seems
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">translated,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like words can&apos;t really tell how you&apos;ve been feeling
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like how you walk through walls</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and lose your pupils in the dark,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            how hours seem to slip through your fingers
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            while you desperately try to cup your hands together,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">all in slow-motion.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &quot;and i looked at my face hundreds and thousands of times
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and nothing had changed,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            then why do i feel so tired and filled to the brim
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            with mingled breaths and car smoke and hourglass sand...&quot;
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">
            &quot;...five, six, seven, eight, nine, ten.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            ten again, only for testing walls and window panes
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            to see if they&apos;re still weightless as well.&quot;
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 64,
    title: "threes",
    isImage: false,
    text: (
      <div id="_idContainer063" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">threes</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            it seems like all the things we have are always hanging by a
            &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;thread,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like certainty&apos;s a thing i&apos;ll never get to taste,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like when you can&apos;t trust yourself and you get
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            blind and deaf and speechless all the same.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;m counting down the hours, minutes, seconds
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">until i can go back again</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">to being myself.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">time comes</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like an angry rapid river down your throat,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            tomorrow, all the things i feel today will
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">be dissolved,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            memories have all the size but lack the strength,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a high-speeding train that crumbles like paper
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">while i forget.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">time comes</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like a speeding bullet that&apos;s aimed for your head,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            actions, reactions and reoccurring patterns,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when you can&apos;t seem to change</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            what&apos;s the point of living the present,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">if every moment stays the same?</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">time comes</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">like soothing summer wind</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            that wrinkles the skin and ages the bones,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            when i can&apos;t seem to solve the issues that keep me still
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i come un-done,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">awareness is a nice thing to have,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">but it&apos;s not enough.</span>
        </p>
      </div>
    ),
  },
  {
    id: 65,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer064" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta31} alt="" />
      </div>
    ),
  },
  {
    id: 66,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer065" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={d3a} alt="" />
      </div>
    ),
  },
  {
    id: 67,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer065" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={d3b} alt="" />
      </div>
    ),
  },
  {
    id: 68,
    title: "a sort of",
    isImage: false,
    text: (
      <div id="_idContainer066" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">a sort of</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a sort of</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            winter wind getting under your clothes kind of loneliness,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">the one that kind of</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            seeps in through your sleeves and in between layers of{" "}
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            cotton-polystyrene blends that cover your skin,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            into your ears and mouth and tear ducts and nose,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">what is steam</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">when i find it in the back of my skull?</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            what is fog when it&apos;s underneath my fingernails?
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a sort of</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            crooked back, ground getting closer kind of situation,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like limbs being made out of thick, dripping smoke
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            with eyes that pierce out and fall on the floor,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a sort of stasis,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like your outside layers were turning into
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">lead.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">get in if you know how to,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;ve been taking care of the place, i have
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            swept the dust, cleaned the windows, beat the carpets,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            come in whenever you feel like it&apos;s the right moment to
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">open me up</span>
        </p>
      </div>
    ),
  },
  {
    id: 69,
    title: "  ",
    isImage: true,
    image: (
      <div id="_idContainer067" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta4} alt="" />
      </div>
    ),
  },
  {
    id: 70,
    title: "unsettle",
    isImage: false,
    text: (
      <div id="_idContainer068" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">unsettle</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">voices linger,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">boundaries thinner,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            bodies twitch in and outside their contours,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            traces remain in the kitchen and on the sofa in the living room,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            grains of soaked sand under ebbing tides,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">is that somebody&apos;s shape</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            that you&apos;re trying to fill with your own
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and trying to find in the back of your mind
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and the palms of your hands?</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things linger,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            words keep dripping from people&apos;s mouths long after
            they&apos;ve been said,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            eyes retrace folds on clothes and the curves of
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">skin stretching on bones and muscles,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            thoughts find themselves back into the same position.
          </span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">whose fault is it?</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            things may seem in balance but they&apos;re never in it,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and some have the right shape to just seep through it,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">foreign objects entering your orbit</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            raising sea levels, changing rivers&apos; courses,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">things linger,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">some stay longer than others,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            some sink to the bottom and fall out of sight
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">but always come back to the shore</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">in rippling waves, in</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">cascades</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">whenever something tips the scale </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            like silhouettes with a familiar, pleasant ebb.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 71,
    title: "wrinkles",
    isImage: false,
    text: (
      <div id="_idContainer069" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">wrinkles</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">wet asphalt, epidermis,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i am starting to feel more like a living body than a human being,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">systems that work and function,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            pathways that link pieces of flesh between themselves,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the whole and the parts and the space in between,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            air mixed with dust particles seamlessly seeping in,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i age under sun beaten skin, i</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            smell like cigarette smoke and forehead wrinkles,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">sticky hair on a scorching afternoon,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            particles of light entering my pores like one of those
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">dry stones thinly veiled in sand.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            define myself through things that i feel like:
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">mid-June worn out table wood,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">rusty welded fences plunged in the soil,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            asphalt in all of its shapes and forms and underneath,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            all kinds of temperatures and weather types and ways
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">this planet spins around its core</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">growing wrinkles.</span>
        </p>
      </div>
    ),
  },
  {
    id: 72,
    title: "nystagmus",
    isImage: false,
    text: (
      <div id="_idContainer070" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">nystagmus</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">there are moments when</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">your gaze drifts away</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and you catch glimpses of yourself</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">staring into a screen for hours on end,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            wondering what&apos;s out there for hours on end,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">moments when you realize</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            mirrors were showing the true you for the past three months,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            but you were too busy acting like you don&apos;t need
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">to look at mirrors or realize things.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">there are moments when</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">other people</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">trace the shape of your nose</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and count your eyelashes.</span>
        </p>
        <br />
        <p className="Basic-Paragraph">
          <span className="tEXT">a perfect reflection.</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            a kind of stillness that&apos;s hard to find,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">a stare that you can sink right into,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            getting used to getting studied and pierced.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            moments when you&apos;d rather close the blinds
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and moments when you&apos;d bang your fist against the table,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">scan the room to discover</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">people with reflections on the floor</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            and people with bruises on the backs of their hands.
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">it&apos;s these times when i remember</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;m not the only one who makes mistakes.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 73,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer071" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta19} alt="" />
      </div>
    ),
  },
  {
    id: 74,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer072" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={licenta5} alt="" />
      </div>
    ),
  },
  {
    id: 75,
    title: "in between",
    isImage: false,
    text: (
      <div id="_idContainer073" className="_idGenObjectStyleOverride-1">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">in between</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">come what may,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the things that will happen to me will do so anyway,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the is, the was and the probably will be,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            i&apos;m not thinking much, i&apos;m just acting on instinct
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            freefalling with your face towards the sky,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            buildings blocking the horizon so you don&apos;t feel like floating
            off &#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;the planet
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">and it&apos;s all in sync,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            your eyes, your hands and the ground beneath,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the loneliness that grows under enough layers of skin,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">i&apos;m in it,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">within the gap between your ribs</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            that grows whenever you breathe deeply enough
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            freefalling to forget the passing of time,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            faces and voices and glass panes and road signs,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">-</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            when your pleasure&apos;s gone, how do i numb myself?
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">this motion will stop one day, this</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">running from what’s actually going on,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            growing up&apos;s a thing you can never set a date for,
          </span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">so come what may,</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">
            the things that will happen will do so anyway.
          </span>
        </p>
      </div>
    ),
  },
  {
    id: 76,
    title: "end",
    isImage: false,
    text: (
      <div id="_idContainer074" className="Basic-Text-Frame">
        <p className="Basic-Paragraph">
          <span className="tEXT CharOverride-2">signals in the night</span>
        </p>
        <p className="Basic-Paragraph">
          <span className="tEXT">octav codrea</span>
        </p>
        <br />
        <p>thank you for reading</p>
        <br />
        <p>2020</p>
        {/* <p className="Basic-Paragraph"><span className="tEXT">This piece of work represents my final project and through it I attempt to synthesize my experiences from recent years, so close and in the same time so far from maturity.</span></p> */}
        {/* <p className="Basic-Paragraph"><span className="tEXT">The texts either mark or represent moods and experiences, while the images help to develop the context. I hope that together they might create an authentic image for the reader where they could resonate with the text and the ideas present in the book.</span></p> */}
        <b></b>
        {/* <p className="Basic-Paragraph"><span className="tEXT">Îndrumători: </span></p>
			<p className="Basic-Paragraph"><span className="tEXT">conf. Stela Lie</span></p>
			<p className="Basic-Paragraph"><span className="tEXT">assistent Carla Duschka</span></p>
			<p className="Basic-Paragraph"><span className="tEXT">Iunie 2019</span></p> */}
      </div>
    ),
  },
  {
    id: 77,
    title: "end",
    isImage: true,
    text: <div></div>,
  },
  {
    id: 78,
    title: " ",
    isImage: true,
    image: (
      <div id="_idContainer071" className="_idGenObjectStyleOverride-1">
        <img className="_idGenObjectAttribute-1" src={coverback} alt="" />
      </div>
    ),
  },
  {
    id: 79,
    title: " ",
    isImage: false,
    text: (
      <div id="_idContainer072" className="_idGenObjectStyleOverride-1"></div>
    ),
  },
];

export default pages;
